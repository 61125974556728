// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_blurClass__yFJxU {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.styles_inputContainer__ZwbSU {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid;
  border-radius: 5px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  padding: 12px;
  color: white;
  background-color: rgba(28, 28, 30, 0.5);
  border-radius: 6px;
  border: 1px solid rgba(92, 92, 92, 1);
}

.styles_input__8kQnn {
  background-color: rgba(28, 28, 30, 0.5);
  border: 1px solid rgba(92, 92, 92, 1);
  border-radius: 6px;
  color: white;
  padding: 8px;
  font-size: 16px;
}

.styles_spinner__dSQ6E {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/views/screens/pages/app-protect-view/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,QAAQ;EACR,SAAS;EACT,YAAY;AACd;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,QAAQ;EAER,gCAAgC;EAChC,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,oBAAoB;EACpB,qBAAqB;EACrB,aAAa;EACb,YAAY;EACZ,uCAAuC;EACvC,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,uCAAuC;EACvC,qCAAqC;EACrC,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EAER,gCAAgC;AAClC","sourcesContent":[".blurClass {\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  opacity: 0.5;\n}\n\n.inputContainer {\n  z-index: 1;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n  border: 1px solid;\n  border-radius: 5px;\n  max-width: 400px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: stretch;\n  align-content: center;\n  padding: 12px;\n  color: white;\n  background-color: rgba(28, 28, 30, 0.5);\n  border-radius: 6px;\n  border: 1px solid rgba(92, 92, 92, 1);\n}\n\n.input {\n  background-color: rgba(28, 28, 30, 0.5);\n  border: 1px solid rgba(92, 92, 92, 1);\n  border-radius: 6px;\n  color: white;\n  padding: 8px;\n  font-size: 16px;\n}\n\n.spinner {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  -webkit-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blurClass": `styles_blurClass__yFJxU`,
	"inputContainer": `styles_inputContainer__ZwbSU`,
	"input": `styles_input__8kQnn`,
	"spinner": `styles_spinner__dSQ6E`
};
export default ___CSS_LOADER_EXPORT___;
