// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SeedPhraseCallout_wrapper__8wr7L {
  flex: 1 1;
  overflow-x: hidden;
  margin: 20px 0 40px 0;
  width: 100%;
}

.SeedPhraseCallout_spacer__2Z6-B {
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/views/screens/pages/seed-phrase-callout/SeedPhraseCallout.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".wrapper {\n  flex: 1;\n  overflow-x: hidden;\n  margin: 20px 0 40px 0;\n  width: 100%;\n}\n\n.spacer {\n  margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SeedPhraseCallout_wrapper__8wr7L`,
	"spacer": `SeedPhraseCallout_spacer__2Z6-B`
};
export default ___CSS_LOADER_EXPORT___;
