// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utils_fullLengthButtonStyle__l5HHL {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.utils_singleLineButtonStyle__T--X- {
  white-space: nowrap;
}

.utils_footerButtonsContainer__W765L {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.utils_docButton__2t2FE {
  padding: 30px 20px;
}`, "",{"version":3,"sources":["webpack://./src/utils/utils.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".fullLengthButtonStyle {\n  width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: pre-wrap;\n}\n\n.singleLineButtonStyle {\n  white-space: nowrap;\n}\n\n.footerButtonsContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%;\n  gap: 12px;\n}\n\n.docButton {\n  padding: 30px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullLengthButtonStyle": `utils_fullLengthButtonStyle__l5HHL`,
	"singleLineButtonStyle": `utils_singleLineButtonStyle__T--X-`,
	"footerButtonsContainer": `utils_footerButtonsContainer__W765L`,
	"docButton": `utils_docButton__2t2FE`
};
export default ___CSS_LOADER_EXPORT___;
