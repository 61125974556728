import { UniswapV2Fork } from '@railgun-community/cookbook';
import {
  FallbackProviderJsonConfig,
  FeeTokenDetails,
  NetworkName,
} from '@railgun-community/shared-models';
import { Log, TransactionReceipt, TransactionResponse } from 'ethers';
import LocalRemoteConfigDevOnly from '../../config/local-remote-config-dev-only.json';
import { LiquidityV2PoolSerialized } from '../../models/liquidity-pool';
import {
  ERC20Amount,
  ERC20AmountRecipient,
  ERC20Token,
  TokenIconKey,
} from '../../models/token';
import { Vault, VaultType } from '../../models/vault';
import { AvailableWallet, ViewOnlyWallet } from '../../models/wallet';
import { RemoteConfig } from '../../redux-store/reducers/remote-config-reducer';

export const MOCK_MNEMONIC =
  'test test test test test test test test test test test junk';

export const MOCK_ERROR_MESSAGE = 'MOCK_ERROR_MESSAGE';

export const MOCK_ETH_ADDRESS = '0xc778417E063141139Fce010982780140Aa0cD5Ab';

export const MOCK_TOKEN: ERC20Token = {
  isAddressOnly: false,
  name: 'TEST TOKEN',
  symbol: 'TOKEN',
  address: '0x00123',
  decimals: 18,
  icon: TokenIconKey.ImageTokenMaticKey,
};

export const MOCK_FEE_TOKEN_DETAILS: FeeTokenDetails = {
  tokenAddress: MOCK_TOKEN.address,
  feePerUnitGas: BigInt('0x2000000000000000000'), // 2x
};

export const MOCK_TOKEN_2: ERC20Token = {
  isAddressOnly: true,
  address: '0x00456',
  decimals: 18,
};

export const MOCK_TOKEN_3: ERC20Token = {
  isAddressOnly: false,
  name: 'TEST TOKEN 3',
  symbol: 'TOKEN3',
  address: '0x00789',
  decimals: 18,
};

export const MOCK_TOKEN_4: ERC20Token = {
  isAddressOnly: false,
  name: 'TEST TOKEN 4',
  symbol: 'TOKEN4',
  address: '0x00789abcdef',
  decimals: 18,
};

export const MOCK_TOKEN_5: ERC20Token = {
  isAddressOnly: false,
  name: 'TEST TOKEN 5',
  symbol: 'TOKEN5',
  address: '0x0987373mmmm',
  decimals: 18,
};

export const MOCK_BASE_TOKEN: ERC20Token = {
  isAddressOnly: false,
  name: 'TEST BASE TOKEN',
  symbol: 'BASE_TOKEN',
  address: '0x009999',
  decimals: 18,
  isBaseToken: true,
  disableWalletRemoval: true,
  iconURL: 'test',
};

export const MOCK_RAIL_WALLET_ID = '456';

export const MOCK_WALLET: AvailableWallet = {
  name: 'MainWallet',
  id: '123',
  railWalletID: MOCK_RAIL_WALLET_ID,
  isActive: true,
  ethAddress: MOCK_ETH_ADDRESS,
  railAddress: 'rail12345',
  createdAt: 1,
  updatedAt: 1,
  addedTokens: {
    [NetworkName.Ethereum]: [
      MOCK_TOKEN,
      MOCK_TOKEN_3,
      MOCK_TOKEN_5,
      MOCK_BASE_TOKEN,
    ],
    [NetworkName.EthereumRopsten_DEPRECATED]: [MOCK_TOKEN, MOCK_TOKEN_3],
  },
  supportedNetworkNames: [
    NetworkName.Ethereum,
    NetworkName.EthereumRopsten_DEPRECATED,
  ],
  isViewOnlyWallet: false,
};

export const MOCK_WALLET_2: AvailableWallet = {
  name: 'SecondaryWallet',
  id: 'abc',
  railWalletID: 'def',
  isActive: false,
  ethAddress: MOCK_ETH_ADDRESS,
  railAddress: 'rail12345',
  createdAt: 2,
  updatedAt: 2,
  addedTokens: { [NetworkName.Ethereum]: [MOCK_TOKEN, MOCK_TOKEN_3] },
  supportedNetworkNames: [NetworkName.Ethereum],
  isViewOnlyWallet: false,
};

export const MOCK_WALLET_3_NO_UPDATED_AT: AvailableWallet = {
  name: 'TertiaryWallet',
  id: 'xyz',
  railWalletID: '999',
  isActive: false,
  ethAddress: MOCK_ETH_ADDRESS,
  railAddress: 'rail12345',
  createdAt: 3,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  updatedAt: undefined as any,
  addedTokens: { [NetworkName.Ethereum]: [MOCK_TOKEN, MOCK_TOKEN_3] },
  supportedNetworkNames: [NetworkName.Ethereum],
  isViewOnlyWallet: false,
};

export const MOCK_WALLET_4_VIEW_ONLY: ViewOnlyWallet = {
  name: 'ViewOnlyWallet',
  id: 'view_only',
  railWalletID: '111',
  isActive: false,
  railAddress: 'rail123lmn',
  createdAt: 4,
  updatedAt: 4,
  addedTokens: { [NetworkName.Ethereum]: [MOCK_TOKEN, MOCK_TOKEN_3] },
  supportedNetworkNames: [NetworkName.Ethereum],
  isViewOnlyWallet: true,
};

export const MOCK_RAILWAY_PROXY_API_URL =
  'https://uber.us.proxy.railwayapi.xyz';
export const MOCK_RAILWAY_PROXY_NFTS_API_URL =
  'https://nfts.us.proxy.railwayapi.xyz';
export const MOCK_RAILWAY_PROXY_POI_AGGREGATOR_URL =
  'https://poi.us.proxy.railwayapi.xyz';

export const MOCK_RAILGUN_WALLET_ADDRESS =
  '0zk1q8hxknrs97q8pjxaagwthzc0df99rzmhl2xnlxmgv9akv32sua0kfrv7j6fe3z53llhxknrs97q8pjxaagwthzc0df99rzmhl2xnlxmgv9akv32sua0kg0zpzts';

export const MOCK_TOKEN_AMOUNT: ERC20Amount = {
  token: MOCK_TOKEN_2,
  amountString: '200',
};
export const MOCK_TOKEN_AMOUNTS: ERC20Amount[] = [
  { token: MOCK_TOKEN, amountString: '100' },
  { token: MOCK_TOKEN_2, amountString: '200' },
];
export const MOCK_TOKEN_AMOUNT_RECIPIENTS: ERC20AmountRecipient[] =
  MOCK_TOKEN_AMOUNTS.map(tokenAmount => ({
    token: tokenAmount.token,
    amountString: tokenAmount.amountString,
    recipientAddress: MOCK_RAILGUN_WALLET_ADDRESS,
    externalUnresolvedToWalletAddress: undefined,
  }));

export const MOCK_EXISTING_FILE = 'existing.file';
export const MOCK_NON_EXISTING_FILE = 'nonexisting.file';

// This should never change.
export const MOCK_EXPECTED_REMOTE_CONFIG_FILEPATH =
  'mock-docs-path/remote-config.json';

export const MOCK_REMOTE_CONFIG: RemoteConfig = {
  minVersionNumberIOS: '1.0.2',
  minVersionNumberAndroid: '2.0.0',
  minVersionNumberWeb: '3.0.0',
  additionalDirectPeers: [],
  wakuPubSubTopic: '',
  wakuPeerDiscoveryTimeout: 60000,
  'bootstrapPeers-': [],
  availableNetworks: {
    [NetworkName.Ethereum]: {
      canSendPublic: true,
      canSendShielded: true,
      canShield: false,
      canUnshield: false,
      canSwapPublic: false,
      canSwapShielded: false,
      canRelayAdapt: false,
    },
  },
  defaultNetworkName: NetworkName.Ethereum,
  networkProvidersConfig: {},
  networkProvidersConfigArchiveNodes: {},
  pollingInterval: 30000,
  proxyApiUrl: MOCK_RAILWAY_PROXY_API_URL,
  proxyNftsApiUrl: MOCK_RAILWAY_PROXY_NFTS_API_URL,
  proxyPoiAggregatorUrl: MOCK_RAILWAY_PROXY_POI_AGGREGATOR_URL,
  publicPoiAggregatorUrls: [],
};

export const MOCK_TRANSACTION_RECEIPT: TransactionReceipt = {
  to: '0x00',
  from: '0x00',
  contractAddress: '0x00',
  gasUsed: BigInt(5),
  gasPrice: 1n,
  logsBloom: '0x00',
  blockHash: '0x00',
  hash: '0x00',
  logs: [
    {
      address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      blockHash:
        '0x1f3ead5e03f19aa2dcd23b825a6ea1df3b44bbdc1dfaa4957a474952b5c18990',
      blockNumber: 12328478,
      data: '0x0000000000000000000000000000000000000000000000000429d069189e0000',
      logIndex: 11,
      topics: [
        '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
        '0x0000000000000000000000009e9f988356f46744ee0374a17a5fa1a3a3cc3777',
        '0x00000000000000000000000022f9dcf4647084d6c31b2765f6910cd85c178c18',
      ],
      transactionHash:
        '0xd912a4fd5b0645b03c60ef11d18fb552172c101d1c4b861e47677ffdba3a079f',
      transactionIndex: 13,
    } as unknown as Log,
    {
      address: '0x40FDe2952a0674a3E77707Af270af09800657293',
      blockHash:
        '0x1f3ead5e03f19aa2dcd23b825a6ea1df3b44bbdc1dfaa4957a474952b5c18990',
      blockNumber: 12328478,
      data: '0xfffffffffffffffffffffffffffffffffffffffffffffff9c34fc463a0a71de80000000000000000000000000000000000000000000000000429d069189e000000000000000000000000000000000000000000000d0e6f5d14439bb6f88270bb00000000000000000000000000000000000000000000022952878ba4c8770464ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff177f',
      logIndex: 14,
      topics: [
        '0xc42079f94a6350d7e6235f29174924f928cc2ac818eb64fed8004e115fbcca67',
        '0x000000000000000000000000e592427a0aece92de3edee1f18e0157c05861564',
        '0x00000000000000000000000022f9dcf4647084d6c31b2765f6910cd85c178c18',
      ],
      transactionHash:
        '0xd912a4fd5b0645b03c60ef11d18fb552172c101d1c4b861e47677ffdba3a079f',
      transactionIndex: 13,
    } as unknown as Log,
    {
      address: MOCK_TOKEN.address,
      blockHash:
        '0x1f3ead5e03f19aa2dcd23b825a6ea1df3b44bbdc1dfaa4957a474952b5c18990',
      blockNumber: 12328478,
      data: '0x0000000000000000000000000000000000000000000000063cb03b9c5f58e218',
      logIndex: 16,
      topics: [
        '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
        '0x00000000000000000000000022f9dcf4647084d6c31b2765f6910cd85c178c18',
        '0x0000000000000000000000009e9f988356f46744ee0374a17a5fa1a3a3cc3777',
      ],
      transactionHash:
        '0xd912a4fd5b0645b03c60ef11d18fb552172c101d1c4b861e47677ffdba3a079f',
      transactionIndex: 13,
    } as unknown as Log,
    {
      address: MOCK_TOKEN.address,
      blockHash:
        '0x1f3ead5e03f19aa2dcd23b825a6ea1df3b44bbdc1dfaa4957a474952b5c18990',
      blockNumber: 12328478,
      data: '0x000000000000000000000000000000000000000000000004e7f951460b0dc0',
      logIndex: 16,
      topics: [
        '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
        '0x0000000000000000000000009e9f988356f46744ee0374a17a5fa1a3a3cc3777',
        '0x00000000000000000000000022f9dcf4647084d6c31b2765f6910cd85c178c18',
      ],
      transactionHash:
        '0xd912a4fd5b0645b03c60ef11d18fb552172c101d1c4b861e47677ffdba3a079f',
      transactionIndex: 13,
    } as unknown as Log,
  ],
  blockNumber: 1,
  confirmations: async () => 1,
  cumulativeGasUsed: BigInt(5),
  type: 1,
  status: 1,
} as unknown as TransactionReceipt;

export const MOCK_TRANSACTION_RESPONSE: TransactionResponse = {
  hash: '123',
  from: MOCK_ETH_ADDRESS,
  wait: () => {
    return Promise.resolve(MOCK_TRANSACTION_RECEIPT);
  },
  nonce: 5,
  chainId: 1n,
  gasLimit: BigInt(20),
  data: 'data',
  value: BigInt(5),
} as TransactionResponse;

export const MOCK_NETWORK_PROVIDERS_CONFIG: MapType<FallbackProviderJsonConfig> =
  LocalRemoteConfigDevOnly.networkProvidersConfig;

export const MOCK_NETWORK_PROVIDERS_CONFIG_ARCHIVE_NODES: MapType<FallbackProviderJsonConfig> =
  LocalRemoteConfigDevOnly.networkProvidersConfigArchiveNodes;

export const MOCK_VAULT: Vault = {
  id: 'id',
  name: 'VAULT_NAME',
  apy: 5.0,
  depositERC20Address: MOCK_TOKEN.address,
  depositERC20Decimals: 18,
  depositERC20Symbol: 'DEPOSIT_TOKEN_SYMBOL 1',
  redeemERC20Symbol: 'REDEEM_TOKEN_SYMBOL 1',
  redeemERC20Address: '0x40324434a0b53dd1ED167Ba30dcB6B4bd7a9536d',
  redeemERC20Decimals: 18,
  type: VaultType.Beefy,
  vaultRate: '1038768990898328136',
};

export const MOCK_VAULT_2: Vault = {
  id: 'id2',
  name: 'VAULT_NAME_2',
  apy: 7.0,
  depositERC20Address: MOCK_TOKEN.address,
  depositERC20Decimals: 18,
  depositERC20Symbol: 'DEPOSIT_TOKEN_SYMBOL 2',
  redeemERC20Symbol: 'REDEEM_TOKEN_SYMBOL 2',
  redeemERC20Address: MOCK_TOKEN_3.address,
  redeemERC20Decimals: 18,
  type: VaultType.Beefy,
  vaultRate: '1038768990898328136',
};

export const MOCK_VAULT_3: Vault = {
  id: 'id3',
  name: 'VAULT_NAME_3',
  apy: 6.0,
  depositERC20Address: MOCK_TOKEN_2.address,
  depositERC20Decimals: 6,
  depositERC20Symbol: 'DEPOSIT_TOKEN_SYMBOL 3',
  redeemERC20Symbol: 'REDEEM_TOKEN_SYMBOL 3',
  redeemERC20Address: '0x45678',
  redeemERC20Decimals: 18,
  type: VaultType.Beefy,
  vaultRate: '1038768990898328136',
};

export const MOCK_PAIR_DATA_WITH_RATE: LiquidityV2PoolSerialized = {
  name: 'MOCK_PAIR_DATA_WITH_RATE',
  uniswapV2Fork: UniswapV2Fork.Uniswap,
  tokenAddressA: MOCK_TOKEN.address,
  tokenSymbolA: MOCK_TOKEN.symbol,
  tokenDecimalsA: BigInt(MOCK_TOKEN.decimals).toString(),
  tokenAddressB: MOCK_TOKEN_3.address,
  tokenSymbolB: MOCK_TOKEN_3.symbol,
  tokenDecimalsB: BigInt(MOCK_TOKEN_3.decimals).toString(),
  pairAddress: '0x123123123',
  pairTokenName: 'MOCK_PAIR_TOKEN_2',
  pairTokenSymbol: 'MOCK_PAIR_TOKEN_SYMBOL_2',
  pairTokenDecimals: BigInt(18).toString(),
  rateWith18Decimals: (10n ** 18n * 900n).toString(),
};

export const MOCK_PAIR_DATA_WITH_RATE_2: LiquidityV2PoolSerialized = {
  name: 'MOCK_PAIR_DATA_WITH_RATE_2',
  uniswapV2Fork: UniswapV2Fork.SushiSwap,
  tokenAddressA: MOCK_TOKEN.address,
  tokenSymbolA: MOCK_TOKEN.symbol,
  tokenDecimalsA: BigInt(MOCK_TOKEN.decimals).toString(),
  tokenAddressB: MOCK_TOKEN_3.address,
  tokenSymbolB: MOCK_TOKEN_3.symbol,
  tokenDecimalsB: BigInt(MOCK_TOKEN_3.decimals).toString(),
  pairAddress: '0xabcdefg',
  pairTokenName: 'MOCK_PAIR_TOKEN_2',
  pairTokenSymbol: 'MOCK_PAIR_TOKEN_SYMBOL_2',
  pairTokenDecimals: BigInt(18).toString(),
  rateWith18Decimals: (10n ** 18n * 1300n).toString(),
};

export const MOCK_PAIR_DATA_WITH_RATE_3: LiquidityV2PoolSerialized = {
  name: 'MOCK_PAIR_DATA_WITH_RATE_3',
  uniswapV2Fork: UniswapV2Fork.Uniswap,
  tokenAddressA: MOCK_TOKEN.address,
  tokenSymbolA: MOCK_TOKEN.symbol,
  tokenDecimalsA: BigInt(MOCK_TOKEN.decimals).toString(),
  tokenAddressB: MOCK_TOKEN_4.address,
  tokenSymbolB: MOCK_TOKEN_4.symbol,
  tokenDecimalsB: BigInt(MOCK_TOKEN_4.decimals).toString(),
  pairAddress: MOCK_TOKEN_5.address,
  pairTokenName: MOCK_TOKEN_5.name,
  pairTokenSymbol: MOCK_TOKEN_5.symbol,
  pairTokenDecimals: BigInt(18).toString(),
  rateWith18Decimals: (10n ** 18n * 1600n).toString(),
};

export const MOCK_PAIR_DATA_WITH_RATE_4: LiquidityV2PoolSerialized = {
  name: 'MOCK_PAIR_DATA_WITH_RATE_3',
  uniswapV2Fork: UniswapV2Fork.Uniswap,
  tokenAddressA: MOCK_BASE_TOKEN.address,
  tokenSymbolA: MOCK_BASE_TOKEN.symbol,
  tokenDecimalsA: BigInt(MOCK_BASE_TOKEN.decimals).toString(),
  tokenAddressB: MOCK_TOKEN_4.address,
  tokenSymbolB: MOCK_TOKEN_4.symbol,
  tokenDecimalsB: BigInt(MOCK_TOKEN_4.decimals).toString(),
  pairAddress: 'base_token_pair_address',
  pairTokenName: 'base_token_pair_name',
  pairTokenSymbol: 'base_token_pair_symbil',
  pairTokenDecimals: BigInt(18).toString(),
  rateWith18Decimals: (10n ** 18n * 1450n).toString(),
};
