// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ERC20BasicListHeader_innerContainer__Skvjm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ERC20BasicListHeader_titleAndIcon__vKROW {
  display: flex;
  align-items: center;
}

.ERC20BasicListHeader_headerText__kuYeq {
  margin-right: 12px;
}

.ERC20BasicListHeader_rightGroup__GdTE7 {
  display: flex;
}

.ERC20BasicListHeader_button__Bjfxi {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/views/screens/tabs/Wallets/WalletsScreen/ERC20BasicList/ERC20BasicListHeader/ERC20BasicListHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".innerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.titleAndIcon {\n  display: flex;\n  align-items: center;\n}\n\n.headerText {\n  margin-right: 12px;\n}\n\n.rightGroup {\n  display: flex;\n}\n\n.button {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `ERC20BasicListHeader_innerContainer__Skvjm`,
	"titleAndIcon": `ERC20BasicListHeader_titleAndIcon__vKROW`,
	"headerText": `ERC20BasicListHeader_headerText__kuYeq`,
	"rightGroup": `ERC20BasicListHeader_rightGroup__GdTE7`,
	"button": `ERC20BasicListHeader_button__Bjfxi`
};
export default ___CSS_LOADER_EXPORT___;
