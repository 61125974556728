import {
  Chain,
  NetworkName,
  RailgunWalletInfo,
  TransactionHistoryItem,
} from '@railgun-community/shared-models';
import {
  BridgeCallEvent,
  CreateRailgunWalletParams,
  CreateViewOnlyRailgunWalletParams,
  DeleteRailgunWalletByIDParams,
  GetRailgunAddressParams,
  GetWalletMnemonicParams,
  GetWalletShareableViewingKeyParams,
  GetWalletTransactionHistoryParams,
  LoadRailgunWalletByIDParams,
  RefreshRailgunBalancesParams,
  RescanFullUTXOMerkletreesAndWalletsParams,
  ResetTXIDMerkletreesV2Params,
  SyncRailgunTransactionsV2Params,
  UnloadRailgunWalletByIDParams,
  ValidateRailgunAddressParams,
} from '../models/bridge';
import { bridgeCall } from './ipc';

/**
 * Creates a new RAILGUN wallet from a mnemonic.
 */
export const createRailgunWallet = async (
  encryptionKey: string,
  mnemonic: string,
  creationBlockNumbers: Optional<MapType<number>>,
): Promise<RailgunWalletInfo> => {
  // Hide mnemonic
  const skipBridgeLogs = true;

  return bridgeCall<CreateRailgunWalletParams, RailgunWalletInfo>(
    BridgeCallEvent.CreateRailgunWallet,
    {
      encryptionKey,
      mnemonic,
      creationBlockNumbers,
    },
    skipBridgeLogs,
  );
};

/**
 * Gets mnemonic string for a RAILGUN wallet by its ID.
 */
export const getWalletMnemonic = async (
  encryptionKey: string,
  railWalletID: string,
): Promise<string> => {
  return bridgeCall<GetWalletMnemonicParams, string>(
    BridgeCallEvent.GetWalletMnemonic,
    {
      encryptionKey,
      railWalletID,
    },
    true, // skipBridgeLogs
  );
};

/**
 * Creates a new View Only RAILGUN wallet from a shareable key.
 */
export const createViewOnlyRailgunWallet = async (
  encryptionKey: string,
  shareableViewingKey: string,
  creationBlockNumbers: Optional<MapType<number>>,
): Promise<RailgunWalletInfo> => {
  // Hide view key
  const skipBridgeLogs = true;

  return bridgeCall<CreateViewOnlyRailgunWalletParams, RailgunWalletInfo>(
    BridgeCallEvent.CreateViewOnlyRailgunWallet,
    {
      encryptionKey,
      shareableViewingKey,
      creationBlockNumbers,
    },
    skipBridgeLogs,
  );
};

/**
 * Loads an existing RAILGUN wallet by its ID.
 */
export const loadRailgunWalletByID = async (
  encryptionKey: string,
  railWalletID: string,
  isViewOnlyWallet: boolean,
): Promise<RailgunWalletInfo> => {
  return bridgeCall<LoadRailgunWalletByIDParams, RailgunWalletInfo>(
    BridgeCallEvent.LoadRailgunWalletByID,
    {
      encryptionKey,
      railWalletID,
      isViewOnlyWallet,
    },
  );
};

/**
 * Unloads an existing RAILGUN wallet by its ID.
 */
export const unloadRailgunWalletByID = async (
  railWalletID: string,
): Promise<void> => {
  return bridgeCall<UnloadRailgunWalletByIDParams, void>(
    BridgeCallEvent.UnloadRailgunWalletByID,
    {
      railWalletID,
    },
  );
};

/**
 * Deletes an existing RAILGUN wallet by its ID.
 */
export const deleteRailgunWalletByID = async (
  railWalletID: string,
): Promise<void> => {
  return bridgeCall<DeleteRailgunWalletByIDParams, void>(
    BridgeCallEvent.DeleteRailgunWalletByID,
    {
      railWalletID,
    },
  );
};

/**
 * Validates the format of a RAILGUN wallet address.
 */
export const validateRailgunAddress = async (
  address: string,
): Promise<boolean> => {
  const validated = await bridgeCall<ValidateRailgunAddressParams, boolean>(
    BridgeCallEvent.ValidateRailgunAddress,
    {
      address,
    },
  );
  return validated;
};

/**
 * Validates the format of an Eth wallet address.
 */
export const validateEthAddress = async (address: string): Promise<boolean> => {
  const validated = await bridgeCall<ValidateRailgunAddressParams, boolean>(
    BridgeCallEvent.ValidateEthAddress,
    {
      address,
    },
  );
  return validated;
};

/**
 * Gets the public RAILGUN address which can receive transfers.
 */
export const getRailgunAddress = (
  railWalletID: string,
): Promise<Optional<string>> => {
  return bridgeCall<GetRailgunAddressParams, Optional<string>>(
    BridgeCallEvent.GetRailgunAddress,
    {
      railWalletID,
    },
  );
};

/**
 * Gets the private viewing key to access all transaction data.
 */
export const getRailgunWalletShareableViewingKey = (
  railWalletID: string,
): Promise<Optional<string>> => {
  return bridgeCall<GetWalletShareableViewingKeyParams, Optional<string>>(
    BridgeCallEvent.GetWalletShareableViewingKey,
    {
      railWalletID,
    },
  );
};

/**
 * Initiates a partial UTXO merkletree rescan for new updates and decrypts wallet balances.
 */
export const refreshRailgunBalances = (
  chain: Chain,
  railgunWalletIdFilter: Optional<string[]>,
): Promise<void> => {
  return bridgeCall<RefreshRailgunBalancesParams, void>(
    BridgeCallEvent.RefreshRailgunBalances,
    { chain, railgunWalletIdFilter },
  );
};

/**
 * Initiates a partial TXID merkletree rescan for new updates and decrypts wallet balances.
 */
export const syncRailgunTransactionsV2 = (
  networkName: NetworkName,
): Promise<void> => {
  return bridgeCall<SyncRailgunTransactionsV2Params, void>(
    BridgeCallEvent.SyncRailgunTransactionsV2,
    { networkName },
  );
};

/**
 * Initiates a full UTXO merkletree rescan.
 */
export const rescanFullUTXOMerkletreesAndWallets = (
  chain: Chain,
  railgunWalletIdFilter: Optional<string[]>,
): Promise<void> => {
  return bridgeCall<RescanFullUTXOMerkletreesAndWalletsParams, void>(
    BridgeCallEvent.RescanFullUTXOMerkletreesAndWallets,
    { chain, railgunWalletIdFilter },
  );
};

/**
 * Initiates a full TXID merkletree rescan.
 */
export const resetFullTXIDMerkletreesV2 = (chain: Chain): Promise<void> => {
  return bridgeCall<ResetTXIDMerkletreesV2Params, void>(
    BridgeCallEvent.ResetFullTXIDMerkletreesV2,
    { chain },
  );
};

/**
 * Gets scanned wallet history as serialized history items, to sync with SavedTransactions.
 */
export const getWalletTransactionHistory = (
  chain: Chain,
  railWalletID: string,
  startingBlock: Optional<number>,
): Promise<TransactionHistoryItem[]> => {
  return bridgeCall<
    GetWalletTransactionHistoryParams,
    TransactionHistoryItem[]
  >(BridgeCallEvent.GetWalletTransactionHistory, {
    chain,
    railWalletID,
    startingBlock,
  });
};
