// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletInfoCallout_infoCallout__dfD1n {
  max-width: 640px;
}`, "",{"version":3,"sources":["webpack://./src/views/components/InfoCallout/WalletInfoCallout/WalletInfoCallout.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF","sourcesContent":[".infoCallout {\n  max-width: 640px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoCallout": `WalletInfoCallout_infoCallout__dfD1n`
};
export default ___CSS_LOADER_EXPORT___;
