// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowSeedPhraseQRCodeModal_wrapper__Jy-Ax {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/views/screens/modals/ShowSeedPhraseQRCodeModal/ShowSeedPhraseQRCodeModal.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF","sourcesContent":[".wrapper {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ShowSeedPhraseQRCodeModal_wrapper__Jy-Ax`
};
export default ___CSS_LOADER_EXPORT___;
