import { isDefined, NetworkName } from '@railgun-community/shared-models';
import { useMemo } from 'react';
import { ERC20TokenFullInfo, FrontendWallet } from '../../models';
import { compareTokenAddress } from '../../utils';
import { useReduxSelector } from '../hooks-redux';

export type FrontendLiquidityPair = {
  tokenA: ERC20TokenFullInfo;
  tokenB: ERC20TokenFullInfo;
};

const checkUnsupportedBaseToken = (
  isRailgun: boolean,
  token: Optional<ERC20TokenFullInfo>,
) => {
  if (!isRailgun) return true; // If not railgun, then all tokens are supported

  return !(token?.isBaseToken ?? false); // If railgun, then only non-base tokens are supported
};

/**
 * Filters and organizes liquidity-related data for a wallet and network. For use on the main liquidity page.
 *
 * @param {Optional<FrontendWallet>} wallet - The wallet object for which to filter liquidity data.
 * @param {NetworkName} networkName - The name of the network.
 * @returns {Object} An object containing supported liquidity pairs and added liquidity tokens.
 */
export const useLiquidityPairsForWalletFilter = (
  wallet: Optional<FrontendWallet>,
  networkName: NetworkName,
  isRailgun: boolean,
) => {
  const { liquidity } = useReduxSelector('liquidity');

  const tokensForNetwork = wallet?.addedTokens[networkName];
  const liquidityPoolsForNetwork = liquidity.forNetwork[networkName]?.allPools;

  const { supportedLiquidityPairList, addedLiquidityTokenList } =
    useMemo(() => {
      const liquidityPairList: FrontendLiquidityPair[] = [];
      const liquidityTokenList: ERC20TokenFullInfo[] = [];

      if (
        !isDefined(tokensForNetwork) ||
        !isDefined(liquidityPoolsForNetwork)
      ) {
        return {
          supportedLiquidityPairList: liquidityPairList,
          addedLiquidityTokenList: liquidityTokenList,
        };
      }

      for (const lpData of liquidityPoolsForNetwork) {
        // Check if tokenAddressA and tokenAddressB both exist in wallet addedTokens
        const tokenA = tokensForNetwork.find(
          t =>
            compareTokenAddress(t.address, lpData.tokenAddressA) && // Is tokenAddressA found in wallet tokens
            checkUnsupportedBaseToken(isRailgun, t), // Filter out basetokens if they are not supported
        );

        const tokenB = tokensForNetwork.find(
          t =>
            compareTokenAddress(t.address, lpData.tokenAddressB) && // Is tokenAddressA found in wallet tokens
            checkUnsupportedBaseToken(isRailgun, t), // Filter out basetokens if they are not supported
        );

        if (isDefined(tokenA) && isDefined(tokenB)) {
          // This wallet supports this liquidity pair, but check if we have already added this pair to the list
          const alreadyAdded = liquidityPairList.some(
            pair =>
              compareTokenAddress(pair.tokenA.address, tokenA.address) &&
              compareTokenAddress(pair.tokenB.address, tokenB.address),
          );

          if (!alreadyAdded) {
            liquidityPairList.push({ tokenA, tokenB });
          }
        }

        // Check if liquidity token address is found in wallet addedTokens
        const liquidityToken = tokensForNetwork.find(t =>
          compareTokenAddress(t.address, lpData.pairAddress),
        );
        if (isDefined(liquidityToken)) {
          // User has the liquidity token added
          liquidityTokenList.push(liquidityToken);
        }
      }

      return {
        supportedLiquidityPairList: liquidityPairList,
        addedLiquidityTokenList: liquidityTokenList,
      };
    }, [tokensForNetwork, liquidityPoolsForNetwork, isRailgun]);

  return {
    supportedLiquidityPairs: supportedLiquidityPairList,
    addedLiquidityTokens: addedLiquidityTokenList,
  };
};
