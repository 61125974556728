import { useUnload } from '@hooks/useUnload';

type Props = {
  message: string;
};

/**
 * The UseUnloadComponent is a component that will trigger a browser alert when the user tries to leave the page.
 * This is useful since if for some reason you want this behavior to not be trigger, you can just not render this component.
 */
export const UseUnloadComponent = ({ message }: Props) => {
  useUnload(e => {
    // Note: Browsers often override this message with a default "Continue? Your changes may not be saved."
    e.returnValue = message;
    return message;
  });

  return <></>;
};
