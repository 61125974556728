// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NFTListHeader_innerContainer__wrFQ0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 16px;
}

.NFTListHeader_titleAndIcon__3vcLB {
  display: flex;
  align-items: center;
}

.NFTListHeader_headerText__760ut {
  margin-right: 12px;
}

.NFTListHeader_searchAndButtons__Exz7r {
  display: flex;
}

.NFTListHeader_button__otoK\\+ {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/views/screens/tabs/NFTs/NFTList/NFTListHeader/NFTListHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".innerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  padding-right: 16px;\n}\n\n.titleAndIcon {\n  display: flex;\n  align-items: center;\n}\n\n.headerText {\n  margin-right: 12px;\n}\n\n.searchAndButtons {\n  display: flex;\n}\n\n.button {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerContainer": `NFTListHeader_innerContainer__wrFQ0`,
	"titleAndIcon": `NFTListHeader_titleAndIcon__3vcLB`,
	"headerText": `NFTListHeader_headerText__760ut`,
	"searchAndButtons": `NFTListHeader_searchAndButtons__Exz7r`,
	"button": `NFTListHeader_button__otoK+`
};
export default ___CSS_LOADER_EXPORT___;
