import { EncryptDataWithSharedKeySerialized } from '@railgun-community/shared-models';
import {
  BridgeCallEvent,
  DecryptAESGCM256Params,
  EncryptAESGCM256Params,
  EncryptDataWithSharedKeyParams,
  GetRandomBytesParams,
  VerifyBroadcasterSignatureParams,
} from '../models/bridge';
import { bridgeCall } from './ipc';

/**
 * Get a random string of bytes using a secure random generator.
 */
export const getRandomBytes = (length: number = 32): Promise<string> => {
  return bridgeCall<GetRandomBytesParams, string>(
    BridgeCallEvent.GetRandomBytes,
    { length },
  );
};

/**
 * Verify if a signature on Fees broadcasted from a Broadcaster is valid.
 */
export const verifyBroadcasterSignature = (
  signature: string,
  data: string,
  signingKey: string,
): Promise<boolean> => {
  const skipBridgeLogs = true;
  return bridgeCall<VerifyBroadcasterSignatureParams, boolean>(
    BridgeCallEvent.VerifyBroadcasterSignature,
    { signature, data, signingKey },
    skipBridgeLogs,
  );
};

/**
 * Generates a sharedKey from a random privkey, and encrypt data asymmetrically.
 * Returns random pubkey which can be used to decrypt data using the unknown external privkey.
 */
export const encryptDataWithSharedKey = (
  data: object,
  externalPubKey: string,
): Promise<EncryptDataWithSharedKeySerialized> => {
  return bridgeCall<
    EncryptDataWithSharedKeyParams,
    EncryptDataWithSharedKeySerialized
  >(BridgeCallEvent.EncryptDataWithSharedKey, { data, externalPubKey });
};

export const encryptAESGCM256 = (
  data: object,
  key: Uint8Array,
): Promise<[string, string]> => {
  return bridgeCall<EncryptAESGCM256Params, [string, string]>(
    BridgeCallEvent.EncryptAESGCM256,
    { data, key },
  );
};

/**
 * Decrypts data using AES GCM 256 algorithm, implemented in Lepton.
 */
export const decryptAESGCM256 = (
  encryptedData: [string, string],
  key: Uint8Array | string,
): Promise<object | null> => {
  return bridgeCall<DecryptAESGCM256Params, object | null>(
    BridgeCallEvent.DecryptAESGCM256,
    { encryptedData, key },
  );
};
