import { IPCEventHandler, NodejsMobileIPC as IPC } from 'nodejs-mobile-ipc2';
import { BridgeCallEvent, BridgeEvent } from '../models/bridge';
import { logDevBridge, logDevBridgeError } from '../utils';

let ipc: Optional<IPC>;

/**
 * Call this function at the very beginning of your app's frontend thread, so to
 * correctly setup the IPC bridge before anyone attempts to use it.
 */
export const bridgeSetup = (
  post: <ParamsType>(event: BridgeCallEvent, result: ParamsType) => void,
  on: (event: BridgeCallEvent, cb: IPCEventHandler) => void,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ipc = new IPC({ post, on });
};

/**
 * Uses IPC to create a new async event, and wait for the response
 * from the Backend (either Web Worker or Nodejs-mobile).
 */
export const bridgeCall = async <ParamsType, ReturnType>(
  event: BridgeCallEvent,
  params: ParamsType,
  skipBridgeLogs = false,
): Promise<ReturnType> => {
  if (!ipc) {
    throw new Error('Bridge IPC not set up');
  }
  !skipBridgeLogs && logDevBridge(`BRIDGE (CALL): ${event}`, params);
  try {
    const result = await ipc.call(event, params);
    !skipBridgeLogs && logDevBridge(`BRIDGE (RESULT): ${event}`, result);
    return result;
  } catch (err) {
    !skipBridgeLogs && logDevBridge(`BRIDGE (ERROR): ${event}`);
    !skipBridgeLogs && logDevBridgeError(err);
    throw err;
  }
};

/**
 * Without sending any data, just listens for events from the Backend (either
 * Web Worker or Nodejs-mobile).
 */
export const bridgeListen = (event: BridgeEvent, cb: IPCEventHandler) => {
  if (!ipc) {
    throw new Error('Bridge IPC not set up');
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ipc.on(event, cb);
};
