import { delay, isDefined } from '@railgun-community/shared-models';
import { CoingeckoApiEndpoint, getCoingeckoData } from './coingecko-service';

// Price data for the currently set currency.
type TokenPrice = {
  price: number;
  tokenAddress: string;
  updatedAt: number;
};

export type CoingeckoPriceMap = MapType<CoingeckoPriceData>;
type CoingeckoPriceData = {
  last_updated_at: number;
};

/**
 * Looks up prices by token address from Coingecko API,
 * given a coingecko network ID and currency string.
 * Ref: https://www.coingecko.com/en/api/documentation
 */
export const priceLookup = async (
  coingeckoNetworkId: string,
  tokenAddresses: string[],
  currency: string,
): Promise<TokenPrice[]> => {
  if (!coingeckoNetworkId) {
    return [];
  }
  if (!tokenAddresses.length) {
    return [];
  }

  const tokenPrices: TokenPrice[] = [];
  for (const tokenAddress of tokenAddresses) {
    const params = {
      contract_addresses: tokenAddress,
      vs_currencies: currency,
      include_last_updated_at: true,
      // include_24hr_change: true,
      // include_market_cap: true,
    };

    const priceResponse = await getCoingeckoData(
      CoingeckoApiEndpoint.PriceLookup,
      coingeckoNetworkId,
      params,
    ).catch(() => {
      return undefined;
    });
    // 30 calls per minute HARD LIMIT, no exceptions.
    // 500ms+ due to potential desync issues
    await delay(2500);
    if (!isDefined(priceResponse)) {
      // it will display N/A for token price instead of showing no prices.
      continue;
    }
    const coingeckoPriceMap: CoingeckoPriceMap = priceResponse.data;
    const coingeckoPriceData = coingeckoPriceMap[tokenAddress];
    if (!isDefined(coingeckoPriceData)) {
      continue; // Token price not found.
    }

    tokenPrices.push({
      tokenAddress: tokenAddress.toLowerCase(),
      updatedAt: coingeckoPriceData.last_updated_at,
      price: (coingeckoPriceData as any)[currency],
    });
  }
  return tokenPrices;
};
