// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddViewOnlyWalletModal_inputContainer__zBpQA {
  margin-bottom: 6px;
}

.AddViewOnlyWalletModal_inputIcon__J7Xi6 {
  padding: 0 10px 0 12px;
}

.AddViewOnlyWalletModal_advancedOptionsButton__e2euT {
  justify-content: "flex-end";
  margin: 12px 0 0 4px;
}

.AddViewOnlyWalletModal_advanced-options__PygF8 {
  margin-top: 6;
}`, "",{"version":3,"sources":["webpack://./src/views/screens/modals/AddViewOnlyWalletModal/AddViewOnlyWalletModal.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;;AAGA;EACE,sBAAA;AAAF;;AAEA;EACE,2BAAA;EACA,oBAAA;AACF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".actionButton {\n}\n.inputContainer {\n  margin-bottom: 6px;\n}\n.inputIcon {\n  padding: 0 10px 0 12px;\n}\n.advancedOptionsButton {\n  justify-content: 'flex-end';\n  margin: 12px 0 0 4px;\n}\n.advancedOptionsText {\n}\n.advanced-options {\n  margin-top: 6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `AddViewOnlyWalletModal_inputContainer__zBpQA`,
	"inputIcon": `AddViewOnlyWalletModal_inputIcon__J7Xi6`,
	"advancedOptionsButton": `AddViewOnlyWalletModal_advancedOptionsButton__e2euT`,
	"advanced-options": `AddViewOnlyWalletModal_advanced-options__PygF8`
};
export default ___CSS_LOADER_EXPORT___;
