import {
  BroadcasterConnectionStatus,
  FallbackProviderJsonConfig,
  FeesSerialized,
  MerkletreeScanStatus,
  NETWORK_CONFIG,
  NetworkName,
  NFTAmount,
  RailgunWalletBalanceBucket,
  TXIDVersion,
} from '@railgun-community/shared-models';
import { SavedTransaction } from '../../models';
import { DEFAULT_CURRENCY } from '../../models/currency';
import { NetworkSettings } from '../../models/network';
import { ERC20BalancesSerialized } from '../../models/token';
import { Vault } from '../../models/vault';
import { AvailableWallet, SavedAddress } from '../../models/wallet';
import { AuthKeyState } from '../../redux-store/reducers/auth-key-reducer';
import { BackGesturesState } from '../../redux-store/reducers/back-gestures-reducer';
import { BroadcasterBlocklistState } from '../../redux-store/reducers/broadcaster-blocklist-reducer';
import { BroadcasterSkiplistState } from '../../redux-store/reducers/broadcaster-skiplist-reducer';
import { BroadcasterStatusState } from '../../redux-store/reducers/broadcaster-status-reducer';
import { NetworkWalletBalanceState } from '../../redux-store/reducers/erc20-balance-reducer-network';
import { RailgunWalletBalanceState } from '../../redux-store/reducers/erc20-balance-reducer-railgun';
import { NetworkLiquidityState } from '../../redux-store/reducers/liquidity-reducer';
import {
  MerkletreeHistoryScanState,
  MerkletreeType,
} from '../../redux-store/reducers/merkletree-history-scan-reducer';
import {
  NetworkTokenPriceState,
  TokenPrices,
} from '../../redux-store/reducers/network-price-reducer';
import { NetworkState } from '../../redux-store/reducers/network-reducer';
import { NFTBalanceState } from '../../redux-store/reducers/nft-balance-reducer-network';
import { RailgunNFTBalanceState } from '../../redux-store/reducers/nft-balance-reducer-railgun';
import { NFTsMetadataState } from '../../redux-store/reducers/nfts-metadata-reducer';
import { POIProofProgressNetworkMap } from '../../redux-store/reducers/poi-proof-progress-reducer';
import { ProofProgressState } from '../../redux-store/reducers/proof-progress-reducer';
import { RemoteConfigState } from '../../redux-store/reducers/remote-config-reducer';
import { SavedAddressesState } from '../../redux-store/reducers/saved-addresses-reducer';
import { SavedTransactionsState } from '../../redux-store/reducers/saved-transactions-reducer';
import { TempNotificationState } from '../../redux-store/reducers/temp-notification-reducer';
import { ToastState } from '../../redux-store/reducers/toast-reducer';
import { TransactionHistoryStatusState } from '../../redux-store/reducers/transaction-history-status-reducer';
import { TransactionsMissingTimestampState } from '../../redux-store/reducers/transactions-missing-timestamp-reducer';
import { NetworkTokenVaultsState } from '../../redux-store/reducers/vaults-reducer';
import { WalletsState } from '../../redux-store/reducers/wallets-reducer';
import { RootState } from '../../redux-store/store';
import {
  MOCK_NETWORK_PROVIDERS_CONFIG,
  MOCK_NETWORK_PROVIDERS_CONFIG_ARCHIVE_NODES,
  MOCK_PAIR_DATA_WITH_RATE,
  MOCK_PAIR_DATA_WITH_RATE_2,
  MOCK_PAIR_DATA_WITH_RATE_3,
  MOCK_PAIR_DATA_WITH_RATE_4,
  MOCK_RAIL_WALLET_ID,
  MOCK_RAILWAY_PROXY_API_URL,
  MOCK_RAILWAY_PROXY_NFTS_API_URL,
  MOCK_RAILWAY_PROXY_POI_AGGREGATOR_URL,
  MOCK_VAULT,
  MOCK_VAULT_2,
  MOCK_WALLET,
  MOCK_WALLET_2,
} from './mock-models';

export const mockWalletsState = (): WalletsState => {
  return {
    active: MOCK_WALLET,
    available: [MOCK_WALLET, MOCK_WALLET_2],
    viewOnly: [],
  };
};

export const mockActiveWalletState = (
  wallet: AvailableWallet,
): WalletsState => {
  return {
    active: wallet,
    available: [wallet],
    viewOnly: [],
  };
};

export const mockNoWalletsState = (): WalletsState => {
  return {
    active: undefined,
    available: [],
    viewOnly: [],
  };
};

export const mockNetworkState = (
  networkName: NetworkName,
  fees: FeesSerialized | null = {
    shieldFeeV2: '25',
    unshieldFeeV2: '25',
    shieldFeeV3: '25',
    unshieldFeeV3: '25',
  },
): NetworkState => {
  return {
    current: {
      ...NETWORK_CONFIG[networkName],
      feesSerialized: fees ?? undefined,
    },
  };
};

export const mockSavedTransactionsState = (
  networkName: NetworkName,
  savedTransactions: SavedTransaction[],
): SavedTransactionsState => {
  return {
    forNetwork: { [networkName]: savedTransactions },
  };
};

export const mockEmptySavedTransactionsState = (): SavedTransactionsState => {
  return {
    forNetwork: {},
  };
};

export const mockTransactionsMissingTimestampState =
  (): TransactionsMissingTimestampState => {
    return {
      forNetwork: {},
    };
  };

export const mockTransactionHistoryStatusState =
  (): TransactionHistoryStatusState => {
    return {
      forNetwork: {},
    };
  };

export const mockTempNotificationState = (): TempNotificationState => {
  return { current: { id: '1', title: '', text: '' } };
};

export const mockToastState = (): ToastState => {
  return {
    immediate: undefined,
    asyncQueue: [],
  };
};

export const mockBackGesturesState = (): BackGesturesState => {
  return {
    enabled: true,
  };
};

export const mockERC20BalancesRailgunState = (
  networkName: NetworkName,
  walletID: string,
  tokenBalancesSerialized: ERC20BalancesSerialized,
): RailgunWalletBalanceState => {
  return {
    forNetwork: {
      [networkName]: {
        forWallet: {
          [walletID]: {
            // TODO-V3: Add test cases for V3 balances. Assuming V2 balances here.
            [TXIDVersion.V2_PoseidonMerkle]: {
              [RailgunWalletBalanceBucket.Spendable]: tokenBalancesSerialized,
              [RailgunWalletBalanceBucket.ShieldPending]: {},
              [RailgunWalletBalanceBucket.MissingInternalPOI]: {},
              [RailgunWalletBalanceBucket.MissingExternalPOI]: {},
              [RailgunWalletBalanceBucket.ShieldBlocked]: {},
              [RailgunWalletBalanceBucket.ProofSubmitted]: {},
              [RailgunWalletBalanceBucket.Spent]: {},
            },
            [TXIDVersion.V3_PoseidonMerkle]: {
              [RailgunWalletBalanceBucket.Spendable]: {},
              [RailgunWalletBalanceBucket.ShieldPending]: {},
              [RailgunWalletBalanceBucket.MissingInternalPOI]: {},
              [RailgunWalletBalanceBucket.MissingExternalPOI]: {},
              [RailgunWalletBalanceBucket.ShieldBlocked]: {},
              [RailgunWalletBalanceBucket.ProofSubmitted]: {},
              [RailgunWalletBalanceBucket.Spent]: {},
            },
          },
        },
      },
    },
  };
};

export const mockERC20BalancesNetworkState = (
  networkName: NetworkName,
  walletID: string,
  tokenBalancesSerialized: ERC20BalancesSerialized,
): NetworkWalletBalanceState => {
  return {
    forNetwork: {
      [networkName]: {
        forWallet: {
          [walletID]: tokenBalancesSerialized,
        },
      },
    },
  };
};

export const mockNFTBalancesRailgunState = (
  networkName: NetworkName,
  walletID: string,
  nftAmounts: NFTAmount[],
): RailgunNFTBalanceState => {
  return {
    forNetwork: {
      [networkName]: {
        forWallet: {
          [walletID]: {
            // TODO-V3: Add test cases for V3 balances. Assuming V2 balances here.
            [TXIDVersion.V2_PoseidonMerkle]: {
              [RailgunWalletBalanceBucket.Spendable]: nftAmounts,
              [RailgunWalletBalanceBucket.ShieldPending]: [],
              [RailgunWalletBalanceBucket.MissingInternalPOI]: [],
              [RailgunWalletBalanceBucket.MissingExternalPOI]: [],
              [RailgunWalletBalanceBucket.ShieldBlocked]: [],
              [RailgunWalletBalanceBucket.ProofSubmitted]: [],
              [RailgunWalletBalanceBucket.Spent]: [],
            },
            [TXIDVersion.V3_PoseidonMerkle]: {
              [RailgunWalletBalanceBucket.Spendable]: [],
              [RailgunWalletBalanceBucket.ShieldPending]: [],
              [RailgunWalletBalanceBucket.MissingInternalPOI]: [],
              [RailgunWalletBalanceBucket.MissingExternalPOI]: [],
              [RailgunWalletBalanceBucket.ShieldBlocked]: [],
              [RailgunWalletBalanceBucket.ProofSubmitted]: [],
              [RailgunWalletBalanceBucket.Spent]: [],
            },
          },
        },
      },
    },
  };
};

export const mockNFTBalancesNetworkState = (
  networkName: NetworkName,
  walletID: string,
  nftAmounts: NFTAmount[],
): NFTBalanceState => {
  return {
    forNetwork: {
      [networkName]: {
        forWallet: {
          [walletID]: nftAmounts,
        },
      },
    },
  };
};

export const mockNFTsMetadataState = (): NFTsMetadataState => {
  return { forNFT: {} };
};

export const mockNetworkPricesState = (
  networkName: NetworkName,
  tokenPrices: TokenPrices,
  currency = DEFAULT_CURRENCY,
): NetworkTokenPriceState => {
  return {
    forNetwork: {
      [networkName]: {
        forCurrency: {
          [currency.code]: tokenPrices,
        },
      },
    },
  };
};

export const mockAuthKeyState = (key?: string): AuthKeyState => {
  return { key };
};

export const mockBroadcasterStatusState = (
  networkName: NetworkName,
  connectionStatus?: BroadcasterConnectionStatus,
): BroadcasterStatusState => {
  return {
    forNetwork: {
      [networkName]: {
        connection: connectionStatus ?? BroadcasterConnectionStatus.Connected,
      },
    },
  };
};

export const mockVaultsState = (
  networkName: NetworkName,
  depositTokenAddress: string,
  depositVaults: Vault[],
  redeemVault: Vault,
): NetworkTokenVaultsState => {
  return {
    forNetwork: {
      [networkName]: {
        depositVaultsForToken: {
          [depositTokenAddress]: {
            list: depositVaults,
            bestApy: depositVaults[0].apy,
          },
        },
        redeemVaultForToken: {
          [redeemVault.redeemERC20Address]: redeemVault,
        },
        updatedAt: Date.now(),
      },
    },
  };
};

export const mockLiquidityState = (): NetworkLiquidityState => {
  return {
    forNetwork: {
      [NetworkName.Ethereum]: {
        allPools: [
          MOCK_PAIR_DATA_WITH_RATE,
          MOCK_PAIR_DATA_WITH_RATE_2,
          MOCK_PAIR_DATA_WITH_RATE_3,
          MOCK_PAIR_DATA_WITH_RATE_4,
        ],
        updatedAt: Date.now(),
      },
    },
  };
};

export const mockRemoteConfigState = (
  networkName: NetworkName,
  networkSettings: NetworkSettings,
  networkProvidersConfig: MapType<FallbackProviderJsonConfig> = {},
  networkProvidersConfigArchiveNodes: MapType<FallbackProviderJsonConfig> = {},
): RemoteConfigState => {
  return {
    current: {
      availableNetworks: {
        [networkName]: networkSettings,
      },
      defaultNetworkName: NetworkName.Ethereum,
      minVersionNumberIOS: '0',
      minVersionNumberAndroid: '0',
      minVersionNumberWeb: '0',
      'bootstrapPeers-': [],
      additionalDirectPeers: [],
      wakuPubSubTopic: '',
      wakuPeerDiscoveryTimeout: 60000,
      networkProvidersConfig,
      networkProvidersConfigArchiveNodes,
      pollingInterval: 30000,
      proxyApiUrl: MOCK_RAILWAY_PROXY_API_URL,
      proxyNftsApiUrl: MOCK_RAILWAY_PROXY_NFTS_API_URL,
      proxyPoiAggregatorUrl: MOCK_RAILWAY_PROXY_POI_AGGREGATOR_URL,
      publicPoiAggregatorUrls: [],
    },
  };
};

export const mockBroadcasterSkiplist = (): BroadcasterSkiplistState => {
  return {
    railgunAddresses: [],
  };
};

export const mockBroadcasterBlocklist = (): BroadcasterBlocklistState => {
  return {
    broadcasters: [],
  };
};

export const mockRemoteConfigStateMultiNetwork = (
  availableNetworks: MapType<NetworkSettings>,
): RemoteConfigState => {
  return {
    current: {
      availableNetworks,
      defaultNetworkName: NetworkName.Ethereum,
      minVersionNumberIOS: '0',
      minVersionNumberAndroid: '0',
      minVersionNumberWeb: '0',
      additionalDirectPeers: [],
      'bootstrapPeers-': [],
      wakuPubSubTopic: '',
      wakuPeerDiscoveryTimeout: 60000,
      networkProvidersConfig: {},
      networkProvidersConfigArchiveNodes: {},
      pollingInterval: 30000,
      proxyApiUrl: '',
      proxyNftsApiUrl: '',
      proxyPoiAggregatorUrl: '',
      publicPoiAggregatorUrls: [],
    },
  };
};

export const mockSavedAddressesState = (
  addresses: SavedAddress[],
): SavedAddressesState => {
  return {
    current: addresses,
  };
};

export const mockMerkletreeHistoryScanState = (
  networkName: NetworkName,
  merkletreeType: MerkletreeType,
  status: MerkletreeScanStatus,
  progress = 1.0,
): MerkletreeHistoryScanState => {
  return {
    forNetwork: {
      [networkName]: {
        forType: {
          [merkletreeType]: {
            status,
            progress,
          },
        },
      },
    },
  };
};

export const mockPOIProofProgressState = (
  networkName: NetworkName,
  // walletID: string,
): POIProofProgressNetworkMap => {
  return {
    forNetwork: {
      [networkName]: {
        forTXIDVersion: {
          [TXIDVersion.V2_PoseidonMerkle]: {
            forWallet: {},
          },
        },
      },
    },
  };
};

export const mockProofProgressState = (): ProofProgressState => {
  return {
    progress: 10,
    status: undefined,
  };
};

export const mockAllReduxStates = (): RootState => {
  return {
    artifactsProgress: { progress: 0 },
    txidVersion: { current: TXIDVersion.V2_PoseidonMerkle },
    network: mockNetworkState(NetworkName.Ethereum),
    wallets: mockWalletsState(),
    erc20BalancesNetwork: mockERC20BalancesNetworkState(
      NetworkName.Ethereum,
      MOCK_WALLET.id,
      {},
    ),
    erc20BalancesRailgun: mockERC20BalancesRailgunState(
      NetworkName.Ethereum,
      MOCK_RAIL_WALLET_ID,
      {},
    ),
    discreetMode: { enabled: false },
    broadcasterStatus: mockBroadcasterStatusState(NetworkName.Ethereum),
    remoteConfig: mockRemoteConfigState(
      NetworkName.Ethereum,
      {} as NetworkSettings,
      MOCK_NETWORK_PROVIDERS_CONFIG,
      MOCK_NETWORK_PROVIDERS_CONFIG_ARCHIVE_NODES,
    ),
    broadcasterSkiplist: mockBroadcasterSkiplist(),
    broadcasterBlocklist: mockBroadcasterBlocklist(),
    networkPrices: mockNetworkPricesState(NetworkName.Ethereum, {}),
    merkletreeHistoryScan: mockMerkletreeHistoryScanState(
      NetworkName.Ethereum,
      MerkletreeType.UTXO,
      MerkletreeScanStatus.Complete,
    ),
    nftBalancesNetwork: mockNFTBalancesNetworkState(
      NetworkName.Ethereum,
      MOCK_WALLET.id,
      [],
    ),
    nftBalancesRailgun: mockNFTBalancesRailgunState(
      NetworkName.Ethereum,
      MOCK_RAIL_WALLET_ID,
      [],
    ),
    nftsMetadata: mockNFTsMetadataState(),
    savedAddresses: mockSavedAddressesState([]),
    savedTransactions: mockEmptySavedTransactionsState(),
    toast: mockToastState(),
    backGestures: mockBackGesturesState(),
    authKey: mockAuthKeyState(),
    proofProgress: mockProofProgressState(),
    transactionHistoryStatus: mockTransactionHistoryStatusState(),
    transactionsMissingTimestamp: mockTransactionsMissingTimestampState(),
    tempNotification: mockTempNotificationState(),
    vaults: mockVaultsState(
      NetworkName.Ethereum,
      MOCK_VAULT.depositERC20Address,
      [MOCK_VAULT, MOCK_VAULT_2],
      MOCK_VAULT_2,
    ),
    liquidity: mockLiquidityState(),
    poiProofProgress: mockPOIProofProgressState(NetworkName.Ethereum),
    proofBatcher: { progress: undefined, status: undefined },
    shieldPOICountdownToast: { isOpen: false },
    omittedPrivateTokens: {
      omittedPrivateTokens: [],
      shouldShowOmittedPrivateTokensModal: false,
    },
  };
};
