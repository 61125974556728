// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPagePaddedContainer_container__zAOx0 {
  width: 84%;
  max-width: 640px;
}`, "",{"version":3,"sources":["webpack://./src/views/components/MainPagePaddedContainer/MainPagePaddedContainer.module.scss"],"names":[],"mappings":"AAAA;EAEE,UAAA;EACA,gBAAA;AAAF","sourcesContent":[".container {\n  // Keep vars in sync with WalletsScreen: innerContainer\n  width: 84%;\n  max-width: 640px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainPagePaddedContainer_container__zAOx0`
};
export default ___CSS_LOADER_EXPORT___;
