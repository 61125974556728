import { AppProtectView } from '@screens/pages/app-protect-view/AppProtectView';

type Props = {
  children: React.ReactNode;
};

export const ProtectWrapperDev = ({ children }: Props): JSX.Element => (
  <AppProtectView sha512="5cc4773eaea6be0f6bce688a8ab0c7c10c99de9c1a0eec4071851f4989585587ce5efe09d563f899b6af2d3c6ece7cda5795cdc517dc93e47d61262a2f54fcd0">
    {children}
  </AppProtectView>
);
