// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ERC20ListRow_iconContainer__m0lrj {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 12px;
}

.ERC20ListRow_tokenIcon__ajaSn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.ERC20ListRow_titleStyles__QmfJJ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/views/components/TokenListRow/ERC20ListRow/ERC20ListRow.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF","sourcesContent":[".iconContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding-right: 12px;\n}\n\n.tokenIcon {\n  width: 30px;\n  height: 30px;\n  border-radius: 15px;\n}\n\n.titleStyles {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": `ERC20ListRow_iconContainer__m0lrj`,
	"tokenIcon": `ERC20ListRow_tokenIcon__ajaSn`,
	"titleStyles": `ERC20ListRow_titleStyles__QmfJJ`
};
export default ___CSS_LOADER_EXPORT___;
