import React from 'react';

export class ErrorBoundary extends React.Component<{ children: any }, object> {
  constructor(props: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError as boolean) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
