/**
 * Transfers
 */
export enum CookbookGeneralRecipeType {
  UnshieldTransferBaseToken = 'UnshieldTransferBaseToken',
}

/**
 * DEX Swaps
 */
export enum CookbookSwapRecipeType {
  ZeroX = 'ZeroX',
}

/**
 * Vaults, Liquid Staking
 */
export enum CookbookFarmRecipeType {
  Deposit = 'Deposit',
  Redeem = 'Redeem',
}

/**
 * Liquidity Pools
 */
export enum CookbookLiquidityRecipeType {
  AddLiquidity = 'AddLiquidity',
  RemoveLiquidity = 'RemoveLiquidity',
}

export type CookbookRecipeType =
  | CookbookGeneralRecipeType
  | CookbookSwapRecipeType
  | CookbookFarmRecipeType
  | CookbookLiquidityRecipeType;
