import { configureStore } from '@reduxjs/toolkit';
import {
  artifactsProgressReducer,
  authKeyReducer,
  backGesturesReducer,
  broadcasterBlocklistReducer,
  broadcasterSkiplistReducer,
  broadcasterStatusReducer,
  discreetModeReducer,
  erc20BalanceNetworkReducer,
  erc20BalanceRailgunReducer,
  liquidityReducer,
  merkletreeHistoryScanReducer,
  networkPricesReducer,
  networkReducer,
  nftBalanceNetworkReducer,
  nftBalanceRailgunReducer,
  nftsMetadataReducer,
  omittedPrivateTokensReducer,
  poiProofProgressReducer,
  proofBatchProgressReducer,
  proofProgressReducer,
  remoteConfigReducer,
  savedAddressesReducer,
  savedTransactionsReducer,
  ShieldPOICountdownToastReducer,
  tempNotificationReducer,
  toastReducer,
  transactionHistoryStatusReducer,
  transactionsMissingTimestampReducer,
  txidVersionReducer,
  vaultsReducer,
  walletsReducer,
} from './reducers';

export const store = configureStore({
  reducer: {
    artifactsProgress: artifactsProgressReducer,
    remoteConfig: remoteConfigReducer,
    wallets: walletsReducer,
    network: networkReducer,
    networkPrices: networkPricesReducer,
    discreetMode: discreetModeReducer,
    erc20BalancesNetwork: erc20BalanceNetworkReducer,
    erc20BalancesRailgun: erc20BalanceRailgunReducer,
    nftBalancesNetwork: nftBalanceNetworkReducer,
    nftBalancesRailgun: nftBalanceRailgunReducer,
    nftsMetadata: nftsMetadataReducer,
    savedAddresses: savedAddressesReducer,
    toast: toastReducer,
    savedTransactions: savedTransactionsReducer,
    backGestures: backGesturesReducer,
    authKey: authKeyReducer,
    broadcasterStatus: broadcasterStatusReducer,
    broadcasterSkiplist: broadcasterSkiplistReducer,
    broadcasterBlocklist: broadcasterBlocklistReducer,
    merkletreeHistoryScan: merkletreeHistoryScanReducer,
    proofProgress: proofProgressReducer,
    transactionsMissingTimestamp: transactionsMissingTimestampReducer,
    transactionHistoryStatus: transactionHistoryStatusReducer,
    tempNotification: tempNotificationReducer,
    vaults: vaultsReducer,
    liquidity: liquidityReducer,
    txidVersion: txidVersionReducer,
    proofBatcher: proofBatchProgressReducer,
    poiProofProgress: poiProofProgressReducer,
    shieldPOICountdownToast: ShieldPOICountdownToastReducer,
    omittedPrivateTokens: omittedPrivateTokensReducer,
  },
  devTools: false,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
