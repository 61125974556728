// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewWalletSuccess_newWalletSuccessContainer__VPaii {
  flex: 1 1;
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  padding-right: 14px;
}

.NewWalletSuccess_lastCard__H5fTI {
  margin-bottom: 24px;
}

.NewWalletSuccess_scrollViewContentContainer__tbWfj {
  padding-bottom: 0px;
}

.NewWalletSuccess_finishButton__LMOuq {
  width: 100%;
  margin-top: 12px;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/views/screens/pages/new-wallet-success/NewWalletSuccess.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,uBAAA;AACF","sourcesContent":[".newWalletSuccessContainer {\n  flex: 1;\n  max-height: 480px;\n  overflow-x: hidden;\n  overflow-y: auto;\n  width: 100%;\n  padding-right: 14px;\n}\n\n.lastCard {\n  margin-bottom: 24px;\n}\n\n.scrollViewContentContainer {\n  padding-bottom: 0px;\n}\n\n.finishButton {\n  width: 100%;\n  margin-top: 12px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newWalletSuccessContainer": `NewWalletSuccess_newWalletSuccessContainer__VPaii`,
	"lastCard": `NewWalletSuccess_lastCard__H5fTI`,
	"scrollViewContentContainer": `NewWalletSuccess_scrollViewContentContainer__tbWfj`,
	"finishButton": `NewWalletSuccess_finishButton__LMOuq`
};
export default ___CSS_LOADER_EXPORT___;
