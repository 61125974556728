// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/img/overlay.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application_selectable-text__UUkZf {
  -webkit-user-select: inherit; /* Safari */ /* Firefox */ /* IE10+/Edge */
  user-select: inherit; /* Standard */
  -khtml-user-select: inherit; /* Konqueror HTML */
  -webkit-touch-callout: inherit;
}

.application_hide-scroll__5HJwV {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

.application_hide-scroll__5HJwV::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.application_overlay-container__Y3ujC {
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top, rgba(19, 21, 25, 0.5), rgba(19, 21, 25, 0.5)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: auto, 200px 75px;
  background-position: center, center;
  background-repeat: no-repeat, repeat;
}

@keyframes application_fade__o2BHk {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}`, "",{"version":3,"sources":["webpack://./src/scss/application.module.scss"],"names":[],"mappings":"AAoBA;EACE,4BAAA,EAAA,WAAA,EACA,YAAA,EACA,eAAA;EACA,oBAAA,EAAA,aAAA;EACA,2BAAA,EAAA,mBAAA;EACA,8BAAA;AAVF;;AAaA;EACE,wBAAA,EAAA,gCAAA;EACA,qBAAA;AAVF;;AAaA;EACE,aAAA,EAAA,kCAAA;AAVF;;AAaA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,gIAAA;EAMA,iCAAA;EACA,mCAAA;EACA,oCAAA;AAfF;;AAkBA;EACE;IACE,YAAA;EAfF;EAiBA;IACE,UAAA;EAfF;EAiBA;IACE,YAAA;EAfF;AACF","sourcesContent":["// [------------------------------ Z-INDEX LEVELS ------------------------------]\n\n$z-index-base: 1;\n$z-index-drawers: 20;\n$z-index-modals: 30;\n$z-index-submenus: 40;\n$z-index-toast: 50;\n$z-index-alerts: 60;\n\n:export {\n  zIndexBase: 1;\n  zIndexDrawers: 20;\n  zIndexModals: 30;\n  zIndexSubmenus: 40;\n  zIndexToast: 50;\n  zIndexAlerts: 60;\n}\n\n// [------------------------------ GLOBAL CLASSES ------------------------------]\n\n.selectable-text {\n  -webkit-user-select: inherit; /* Safari */\n  -moz-user-select: inherit; /* Firefox */\n  -ms-user-select: inherit; /* IE10+/Edge */\n  user-select: inherit; /* Standard */\n  -khtml-user-select: inherit; /* Konqueror HTML */\n  -webkit-touch-callout: inherit;\n}\n\n.hide-scroll {\n  -ms-overflow-style: none; /* for Internet Explorer, Edge */\n  scrollbar-width: none;\n}\n\n.hide-scroll::-webkit-scrollbar {\n  display: none; /* for Chrome, Safari, and Opera */\n}\n\n.overlay-container {\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  background-image: linear-gradient(\n      to top,\n      rgba(19, 21, 25, 0.5),\n      rgba(19, 21, 25, 0.5)\n    ),\n    url('../../public/img/overlay.png');\n  background-size: auto, 200px 75px;\n  background-position: center, center;\n  background-repeat: no-repeat, repeat;\n}\n\n@keyframes fade {\n  0% {\n    opacity: 0.3;\n  }\n  50% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0.3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zIndexBase": `1`,
	"zIndexDrawers": `20`,
	"zIndexModals": `30`,
	"zIndexSubmenus": `40`,
	"zIndexToast": `50`,
	"zIndexAlerts": `60`,
	"selectable-text": `application_selectable-text__UUkZf`,
	"hide-scroll": `application_hide-scroll__5HJwV`,
	"overlay-container": `application_overlay-container__Y3ujC`,
	"fade": `application_fade__o2BHk`
};
export default ___CSS_LOADER_EXPORT___;
