import React from 'react';
import { Provider } from 'react-redux';
import { WrapperComponent } from '@testing-library/react-hooks';
import { mockStore } from './mock-store';

export const getMockProviderWithoutNavigationController = (
  children: React.ReactNode,
) => {
  return <Provider store={mockStore}>{children}</Provider>;
};

// Used as wrapped for hook tests, so mock STORE is loaded in the Provider.
export const getMockProviderHookWrapper = <
  ComponentType,
>(): WrapperComponent<ComponentType> => {
  return ({ children }: any) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    getMockProviderWithoutNavigationController(children);
};
