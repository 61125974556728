import { LiquidityV2Pool, UniswapV2Fork } from '@railgun-community/cookbook';
import { isDefined, NetworkName } from '@railgun-community/shared-models';
import { useMemo } from 'react';
import {
  compareTokenAddress,
  convertSerializedToLiquidityPool,
} from '../../utils';
import { useReduxSelector } from '../hooks-redux';
import { FrontendLiquidityPair } from './useLiquidityPairsForWalletFilter';

const liquidityPoolDataSorter = (
  a: LiquidityV2Pool,
  b: LiquidityV2Pool,
): number => {
  if (
    a.uniswapV2Fork === UniswapV2Fork.Uniswap &&
    b.uniswapV2Fork !== UniswapV2Fork.Uniswap
  ) {
    return -1; // a comes before b
  } else if (
    a.uniswapV2Fork !== UniswapV2Fork.Uniswap &&
    b.uniswapV2Fork === UniswapV2Fork.Uniswap
  ) {
    return 1; // b comes before a
  }
  return 0; // no change in order
};

/**
 * Filters and sorts redux liquidity pool data based on a given liquidity pair and network. For use on the AddLiquidityInitial screen.
 *
 * @param {FrontendLiquidityPair} liquidityPair - The frontend liquidity pair object.
 * @param {NetworkName} networkName - The name of the network.
 * @returns {Object} An object containing filtered and sorted liquidity pool data.
 */
export const useLiquidityPoolsForPairFilter = (
  liquidityPair: FrontendLiquidityPair,
  networkName: NetworkName,
) => {
  const { liquidity } = useReduxSelector('liquidity');
  const liquidityPoolsForNetwork = liquidity.forNetwork[networkName]?.allPools;

  const tokenAddressA = liquidityPair.tokenA.address;
  const tokenAddressB = liquidityPair.tokenB.address;

  const { filteredLiquidityPoolList } = useMemo(() => {
    let liquidityPoolList: LiquidityV2Pool[] = [];

    if (!isDefined(liquidityPoolsForNetwork)) {
      return {
        filteredLiquidityPoolList: liquidityPoolList,
      };
    }

    // Get all lpData objects that have a matching tokenA and tokenB
    liquidityPoolList = liquidityPoolsForNetwork
      .map(convertSerializedToLiquidityPool)
      .filter(
        lp =>
          compareTokenAddress(lp.tokenAddressA, tokenAddressA) &&
          compareTokenAddress(lp.tokenAddressB, tokenAddressB),
      );

    // Sort list to have uniswap on top
    liquidityPoolList.sort(liquidityPoolDataSorter);

    return {
      filteredLiquidityPoolList: liquidityPoolList,
    };
  }, [tokenAddressA, tokenAddressB, liquidityPoolsForNetwork]);

  return {
    liquidityPoolList: filteredLiquidityPoolList,
  };
};
