import { AnyAction, Reducer } from 'redux';
import { AppDispatch, RootState, store } from '../../redux-store/store';

export const setMockStoreState = (mockStoreState: RootState) => {
  store.getState = () => mockStoreState;
};

export const mockStore = store;

export const mockAppDispatch = (
  reducer: Reducer,
  storeKey: keyof RootState,
) => {
  return ((action: AnyAction) => {
    const currentState = mockStore.getState()[storeKey];
    const newState = reducer(currentState, action);
    setMockStoreState({
      ...mockStore.getState(),
      [storeKey]: newState,
    });
  }) as AppDispatch;
};
