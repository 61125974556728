// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListItem_container__B4R4o {
  height: 100%;
}

.ListItem_row__ZzE01 {
  display: flex;
  height: 100%;
  align-items: center;
}

.ListItem_title__sP6p9 {
  color: text();
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ListItem_descriptionContainer__wT4aP {
  padding: 6px 0 4px 0;
  flex: 1 1;
  min-width: 0px;
}

.ListItem_description__0zsJ- {
  font-size: 14px;
  color: text();
}

.ListItem_marginVerticalNone__PemWo {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ListItem_iconMarginLeft__G4Yw\\+ {
  margin-left: 0px;
  margin-right: 16px;
}

.ListItem_iconMarginRight__zipb5 {
  margin-right: 0px;
}

.ListItem_leftContainer__DBDEj {
  margin: 6px 16px 6px 0;
  padding-left: 16px;
  display: flex;
  flex: 1 1;
  min-width: 0px;
}

.ListItem_rightContainer__ajlSB {
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.ListItem_rightContainerEven__LkKaj {
  min-width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/views/components/ListItem/ListItem.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,oBAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".container {\n  height: 100%;\n}\n\n.row {\n  display: flex;\n  height: 100%;\n  align-items: center;\n}\n\n.title {\n  color: text();\n  font-size: 16px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.descriptionContainer {\n  padding: 6px 0 4px 0;\n  flex: 1;\n  min-width: 0px;\n}\n\n.description {\n  font-size: 14px;\n  color: text();\n}\n\n.marginVerticalNone {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\n.iconMarginLeft {\n  margin-left: 0px;\n  margin-right: 16px;\n}\n\n.iconMarginRight {\n  margin-right: 0px;\n}\n\n.leftContainer {\n  margin: 6px 16px 6px 0;\n  padding-left: 16px;\n  display: flex;\n  flex: 1;\n  min-width: 0px;\n}\n\n.rightContainer {\n  height: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.rightContainerEven {\n  min-width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ListItem_container__B4R4o`,
	"row": `ListItem_row__ZzE01`,
	"title": `ListItem_title__sP6p9`,
	"descriptionContainer": `ListItem_descriptionContainer__wT4aP`,
	"description": `ListItem_description__0zsJ-`,
	"marginVerticalNone": `ListItem_marginVerticalNone__PemWo`,
	"iconMarginLeft": `ListItem_iconMarginLeft__G4Yw+`,
	"iconMarginRight": `ListItem_iconMarginRight__zipb5`,
	"leftContainer": `ListItem_leftContainer__DBDEj`,
	"rightContainer": `ListItem_rightContainer__ajlSB`,
	"rightContainerEven": `ListItem_rightContainerEven__LkKaj`
};
export default ___CSS_LOADER_EXPORT___;
